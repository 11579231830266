import React from "react";

const smallCard = ({src, alt, title, text, link}) => (
    <>
        <div className="smallcard-wrapper">
            <div className="smallcard-img">
                <img src={src} alt={alt}/>
            </div>
            <div className="smallcard-body">
                <h5>{title}</h5>
                <p>{text}</p>
                <span><a href={link}><button className="smallcard-button">ПОДРОБНЕЕ</button></a></span>
            </div>
        </div>
    </>
)

export default smallCard;