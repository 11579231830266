import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ProjectCardTemplate from '../ProjectsCards/projectCard';

import BimboCardImg from '../../../images/projectspage/projectscards/bimbo2.jpg';
import SapsanCardImg from '../../../images/projectspage/projectscards/sapsan.jpg';
import KolomenskiyCardImg from '../../../images/projectspage/projectscards/kolomenskiy.jpg';
import AltiCardImg from '../../../images/projectspage/projectscards/alti-milkprom.jpg';

const IceWaterProjectsCards = () => {
    return(
        <Container>
            <Row>
                <Col>
                    <ProjectCardTemplate 
                        src = {BimboCardImg}
                        alt = "Завод по производству замороженных хлебобулочных изделий"
                        title = "BIMBO QSR RUS" 
                        subtitle= "Завод по производству замороженных хлебобулочных изделий"
                        text = "Производство булочек, пирожков и других мучных изделий."
                        link = "/realizovannye_proekty/bimbo"
                    />
                </Col>
                <Col>
                    <ProjectCardTemplate 
                        src = {SapsanCardImg}
                        alt = "Фабрика по производству кондитерских, кулинарных и хлебобулочных изделий."
                        title = 'FOODCODE' 
                        subtitle= "Фабрика по производству кондитерских, кулинарных и хлебобулочных изделий."
                        text = 'Производство замороженной безглютеновой продукции.'
                        link = "/realizovannye_proekty/foodcode"
                    />
                </Col>
            </Row>
            <Row>
            <Col>
                    <ProjectCardTemplate 
                        src = {KolomenskiyCardImg}
                        alt = "Хлебный завод «Коломенский»"
                        title = "Хлебный завод «Коломенский»" 
                        subtitle= "Новый завод по производству хлебобулочных и кондитерских изделий."
                        text = "Общая площадь производства 36’700м², готовая продукция 225т/сутки."
                        link = "/realizovannye_proekty/kolomenskiy"
                    />
                </Col>
                <Col>
                    <ProjectCardTemplate 
                        src = {AltiCardImg}
                        alt = "Производство сыров с белой плесенью Alti"
                        title = "ООО «Милкпром». Компания MULTIPRO." 
                        subtitle= 'Производство сыров с белой плесенью "Alti"'
                        text = "Новое сырное производство."
                        link = "/realizovannye_proekty/milkprom"
                    />
                </Col>
            </Row>
        </Container>
        
    )
}

export default IceWaterProjectsCards