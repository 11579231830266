import React from "react"
import Seo from "../../../components/seo"

import IceWater from "./ice-water"


const IceWaterIndex = () => (

  <>
    <Seo 
      title="Льдоаккумуляторы и оборудование для получения ледяной воды"
      description="Льдоаккумуляторы и установки получения ледяной воды для систем охлаждения предприятий"
      keywords="льдоаккумулятор, ледяная вода, система охлаждения, установка ледяной воды"
    />
    <IceWater />
  </>
  
)

export default IceWaterIndex