import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import TopDark from '../../../components/Modules/Top/TopDarkRelative'
import Footer from '../../../components/Modules/FooterPage'
import IceWaterProjectsCards from '../../../components/Modules/PageCards/icewatercards';

import SmallCard from '../../../components/Modules/SmallCards/smallcard'
import IceAccuImg from '../../../images/competency/iceaccu.jpg'


const IceWaterPage = () => {

    return(
        <>
            <Container fluid>
                <Row>
                    <Col>
                        <TopDark />
                    </Col>
                </Row>
            </Container>
                    <div className="refeng-page-container">
                        <div className="postcontent">
                            <h1>Ледяная вода</h1>
                            <h2>Оборудование для получения ледяной воды</h2>
                            <br/>
                            <div className='page-wrapper'>
                                <div className='post-context'>
                                    {/* Context */}
                               

                                {/* <p>Для каждого вида продукции необходимы особые оптимальные условия хранения, позволяющие сохранять продукцию в надлежащем виде и надлежащего качества.</p>
                                <p>ООО "Рефинжиниринг" обладает большим опытом в проектировании и строительстве складов для охлаждения разнообразных продуктов. 
                                Нами были спроектированы и введены в эксплуатацию склады для хранения пищевых продуктов, камеры для хранения овощей и фруктов, хранилища фармацевтической продукции. 
                                Тщательный анализ и подбор необходимого оборудования позволил оптимизировать затраты нашим Заказчикам, снизить издержки на хранение готовой продукции, оптимизировать логистику.</p> */}
                                
                                <h3>Проекты с использованием ледяной воды: </h3>
                                <br/><br/>
                                <IceWaterProjectsCards />
                                    
                                {/* End Context */}
                                </div>

                                {/* Competences Cards  */}
                                <div className='post-sidebar' style={{height: 'auto', borderLeft: '1px solid #aaa', paddingLeft: '20px'}}>
                                    <h4>Получение ледяной воды:</h4>
                                    <br></br>
                                    <SmallCard 
                                        src= {IceAccuImg}
                                        alt="Установки для получения ледяной воды, льдоаккумуляторы."
                                        title="Льдоаккумуляторы"
                                        text="Установки для получения ледяной воды."
                                        link="/akkumulyatory-lda"
                                    />
                                </div>
                                 {/* End Competences Cards  */}

                            </div>
                        </div>
                    </div>
            <Footer />
        </>
    )
}

export default IceWaterPage